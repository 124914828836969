<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
:saveReport="getCertificateETIReportExcel"

).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TableCertificateETI',
  data () {
    return {
      headers: [
        { value: 'sailor.id', text: this.$t('sailorId') },
        { value: 'ntz_number', text: this.$t('number') },
        { value: 'sailorFullName', text: this.$t('fullName') },
        { value: 'course_traning.name_ukr', text: this.$t('course') },
        { value: 'ntz.name_ukr', text: this.$t('nameInstitution') },
        { value: 'ntz_number', text: this.$t('numberStatement') },
        { value: 'date_start', text: this.$t('dateIssue') },
        { value: 'date_end', text: this.$t('dateTermination') },
        { value: 'status_document', text: this.$t('status') },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: (item) => ({ name: 'certification-certificates-info', params: { id: item.sailor.id, documentID: item.id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.listCertificateETI,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getListCertificateETIReport', 'getCertificateETIReportExcel']),
    async getData (params) {
      await this.getListCertificateETIReport({ ...this.$route.params, ...params })
    }

  }
}
</script>
